/* Header.css */
header {
  background-color: transparent;
  color: #020202;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.logo {
  width: 100%;
  margin-top: -50px;
  margin-left: 5vh;
}

.logoImg {
  width: 100px; /* Adjust the width as needed */
  position: absolute;
  top: -10px;
  z-index: 1;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 60px;
}

nav ul li {
  margin-right: 20px;
}

nav ul li:last-child {
  margin-right: 80px;
}

nav ul li a {
  color: #000000;
  text-decoration: none;
  position: relative;
}

nav ul li a.active.HomeLinksActive::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #ffffff;
  position: absolute;
  bottom: -5px;
  left: 0;
}

nav ul li a.active.GalleryLinkActive::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #000000;
  position: absolute;
  bottom: -5px;
  left: 0;
}

.menu-icon {
  display: none;
  cursor: pointer;
}
.links {
  color: #010101;
  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.48px;
}
.signIn_icon {
  display: flex;
  width: 50px;
  height: 50px;
  padding: 25px 0px 24.313px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--Brand-color, #2350a2);
  margin-top: -10px;
}
.logoImg {
  width: 100px;
}
@media screen and (max-width: 768px) {
  .logo {
    max-width: 80px;
    margin-left: 0;
  }

  .menu {
    background-color: #00000065;
  }

  .signIn_icon {
    float: center;
  }
  .user {
    margin-right: 20px !important;
  }
  nav ul li {
    text-align: left;
  }
  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 64px; /* Adjust the top position as needed */
    left: 0;
    background-color: #ffffff00; /* Add background color for menu */
    width: 100%;
    padding: 25px 10px;
    backdrop-filter: blur(8px);
  }

  nav ul.open {
    display: flex;
  }

  .menu-icon {
    display: block;
    margin-right: 20px;
  }
}
@media screen and (max-width: 400px) {
  .logo {
    max-width: 80px;
  }
  .logoImg {
    width: 90px;

    margin-left: -5%;
  }

  nav ul {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 70px; /* Adjust the top position as needed */
    left: 0;
    background-color: #cccccc00; /* Add background color for menu */
    width: 100%;
    padding: 10px;
  }

  nav ul.open {
    display: flex;
  }

  .menu-icon {
    display: block;
    margin-right: 20px;
  }
}
