.footer_container {
  background: var(--grey-1000, #252525);
  height: auto;
  flex-shrink: 0;
  margin: 0;
  padding: 40px 42px 35px 112px;
}

.get_toch {
  color: #fff;
    font-family: "Cormorant Garamond", serif;;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 58.5px */
  letter-spacing: 0.9px;
}
.footer_subtext {
  color: #fff;
  /* Headings/H5/Regular */
    font-family: "Cormorant Garamond", serif;;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 36px */
}

#p {
  color: var(--text-icon-dark-body, #e4e4e5);
  /* Body/TextL/Regular */
    font-family: "Cormorant Garamond", serif;;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 36px */
  letter-spacing: 0.48px;
  padding: 0px;
  margin: 0px;
  width: auto;
}

#p1 {
  color: #eee;
    font-family: "Cormorant Garamond", serif;;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 28.6px */
  letter-spacing: 0.44px;
}
#pHead {
  color: #ffffff;
    font-family: "Cormorant Garamond", serif;;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 39px */
  letter-spacing: 0.6px;
}

.footer_downcontainer {
  margin-top: 24px;
}

.centered_img {
  display: block;
  margin: 0 auto; /* Horizontally center the image */
  text-align: center; /* Center inline content inside the image container */
  max-width: 100%;
}

.arrow_img {
  transform: rotate(-4deg);
  flex-shrink: 0;
  height: 100px;
  color: #fff;
}

.sec_div {
  display: flex;
  flex-direction: column;
}

.stylingCon {
  display: flex;
  justify-content: space-between;
}

.get_touch_cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.get_touch_cont:hover .arrow_img {
  color: yellow; /* Background color change on hover */
}
.sec_div{
  margin-left: 45vh;
}

@media (max-width: 1380px){
  .sec_div{
    margin-left: 25vh;
  }
}
@media (max-width: 1028px){
  .sec_div{
    margin-left: 5vh;
  }
}

/* Styles for screens with a maximum width of 767px (e.g., mobile phones) */
@media (max-width: 998px) {
  .get_toch {
    font-size: 45px;
  }
  .third_div{
    margin-left: 10vh;
  }
  #pHead{
    font-size: larger !important;
  }
  #p1{
    font-size: medium !important;
  }
}

/* Styles for screens with a maximum width of 767px (e.g., mobile phones) */
@media (max-width: 600px) {
  .stylingCon {
    display: flow-root;
    justify-content: center;
    align-items: center;
  }
  .sec_div{
    margin-left: -5vh;
    margin-top: 3vh;
  }
  .third_div{
    margin-left: -5vh;
    margin-top: 3vh;
  }
.fir_div{
  margin-left: -5vh;
}
  #pHead{
    font-size: larger !important;
  }
  #p1{
    font-size: medium !important;
  }
}




