/* Gallery Title */

/* Button Group */
.ButtonGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-family: "Cormorant Garamond", serif;
}

.ButtonGroup .btn {
  margin-right: 10px;
  font-family: "Cormorant Garamond", serif;
}

/* Month Buttons */
.month-button {
  width: 100%;
  margin-bottom: 10px;
  font-family: "Cormorant Garamond", serif;
}

/* Image Cards */
.Card {
  margin-bottom: 20px;
  font-family: "Cormorant Garamond", serif;
}

.scrollable-months {
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
  border-bottom: 1px solid #e9e4c9;
  margin-bottom: 5px;
}

.scrollable-months .btn {
  width: 100%;
  margin-bottom: 5px;
}

.dashed-container-upload {
  position: relative;
}

.dashed-container-upload input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.dashed-container-upload img {
  max-width: 60%;
  max-height: 100%;
}

.SelectDate {
  font-size: 20px;
  padding: 10px 10px;
  color: #000000;
  border-bottom: 1px solid #bebebe;
  text-align: left;
}
.datePicker {
  padding: 10px;
  border: 0px;
  border-radius: 20px;
}

.SelectYear,
.SelectMonth {
  font-weight: bold;
}
.scrollable-months::-webkit-scrollbar {
  display: none;
}
select {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 0px;
  border-radius: 5px;
  cursor: pointer;
  color: #000000;
  font-family: "Cormorant Garamond", serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
  letter-spacing: 0.64px;
  outline: none;
}
.months_font {
  color: #afaeae;
  font-family: "Cormorant Garamond", serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
  letter-spacing: 0.64px;
  border: 0px;
  background-color: rgb(255, 255, 255);
  text-align: left !important;
  padding-left: 50px !important;
}
.months_font:hover {
  background-color: rgb(255, 255, 255);
  color: var(--Brand-color, #2350a2);
}

.MonthsButtonActive {
  /* border: 1px solid; */
  color: var(--Brand-color, #2350a2);
  padding-left: -50px;
}

.upload-container label {
  cursor: pointer;
}
/* Upload Container */
.upload-container {
  background-image: url("../../assets/uploadbackground.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  width: 88%;
  height: 540px;
  flex-shrink: 0;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 3vh;
}

.dashed-container {
  /* border: 2px dashed #ccc; */

  padding: 20px;
  width: 100%;
  height: 540px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashed-container-upload {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px dashed var(--Brand-color, #2350a2);
  border-radius: 5px;
  padding: 20px;
  width: 100%; /* Adjust width if needed */
  max-width: 400px; /* Adjust max-width if needed */
  margin: 0 auto; /* This centers the container horizontally */
  text-align: center;
}

.dashed-container-preview-bg {
  border: 0px solid;
  height: 100%;
  padding: 30px;
}
.dashed-container-preview-body {
  border: 0px solid;
  height: 85%;
  border-radius: 20px;

  background-color: white;
  overflow: hidden;
  overflow-y: scroll;
  overflow-x: hidden;
}
.dashed-container-Buttons {
  text-align: right;
  padding-top: 10px;
  display: flex;
  align-items: center;
}
.uploadButton {
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;

  color: #ffffff;
  background-color: var(--Brand-color, #2350a2);
  transition: background-color 0.3s;
  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: 0.4px;
}
.dateForUpload {
  padding: 12px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 2px solid;
  color: #1a1a1a;
  background-color: transparent;

  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: 0.4px;
  margin-right: 20px;
  position: relative;
}

.dateForUpload::-webkit-calendar-picker-indicator {
  border: 0px solid;

  width: 100%;
  background-color: transparent;
  position: absolute;
  cursor: pointer;
  padding-left: 150%;
}

.PreviewImage {
  height: 150px;
  width: 160px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 10px;
  border-radius: 10px;
  border: 0px solid;
  background-color: rgb(243, 241, 241);
}
.deleteButton {
  float: right;
  background-color: rgb(255, 255, 255);
  border-radius: 30px;
  color: black;
  height: 35px;
  width: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  margin: 10px -2px;
}
.deleteButton:hover {
  background-color: rgb(255, 183, 183);
}
.upload-container label {
  cursor: pointer;
}
.arrowIcon {
  margin-right: 10px !important;
}
/* Responsive Styles */
@media screen and (min-width: 226px) and (max-width: 576px) {
  .uploadButton {
    padding: 1px 10px !important;
    margin-top: 2vh;
    height: 4vh;
  }.SelectDate{
    font-size: 20px;
  }
 
}
@media (max-width: 576px) {
  .Card {
    flex-basis: 100%;
  }
  .dashed-container-Buttons {
    flex-direction: column;
  }
  .uploadButton {
    padding: 5px 10px;
    margin-top: 2vh;
  }
  .dateForUpload::-webkit-calendar-picker-indicator {
    padding-left: 160%;
  }
  .dateForUpload {
    padding: 6px 12px;
    margin-top: 1vh;
  }
  .upload-container {
    height: 580px;
    flex-shrink: 0;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 3vh;
  }
}

@media (min-width: 576px) {
  .Card {
    flex-basis: calc(50% - 20px);
  }
}

@media (min-width: 768px) {
  .Card {
    flex-basis: calc(33.3333% - 20px);
  }

  .months_font {
    padding-left: 0px !important;
    text-align: left !important;
  }
}
@media (max-width: 768px) {
  .upload-container {
    padding: 10px;
  }

  .months_font {
    padding-left: 0px !important;
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  .Card {
    flex-basis: calc(25% - 20px);
  }
}
