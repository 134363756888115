@font-face {
  font-family: AvenirLTProLight;
  src: url(../../../public/AvenirLTProLight.otf);
}

.background-video-container {
  position: relative;
  height: 98vh;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  margin-top: -11vh;
}

.background-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.content-overlay {
  color: #fff;
  margin-left: 5rem;
  z-index: 0;
  width: 48%;
}

.gallery_btn {
  /* Add styles for the gallery button */
  color: #fff;
  background-color: #007bff;
  /* Example button color, adjust as needed */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.image {
  width: 100%;
  height: auto;
  z-index: 1;
  margin-top: -96px;
}

.heading {
  color: #fff;
  font-family: "Cormorant Garamond", serif;
  font-size: calc(1.2rem + 2vw);
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 1.44px;
  text-align: left;
}

.projectName {
  color: #1a1a1a;
  font-family: "Cormorant Garamond", serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  letter-spacing: 0.72px;
  margin-bottom: 8px !important;
}

.para {
  color: #434343;
  font-family: AvenirLTProLight;
  font-size: 16px;
  margin-top: 1rem;
  font-style: normal;

  line-height: 170%;
  /* 36px */
  letter-spacing: 0.48px;
  width: "40%";
  text-align: justify;
}

.about {
  color: #2350a2;
  font-family: "Cormorant Garamond", serif;
  font-size: 40px;
  font-style: normal;

  line-height: 130%;
  /* 78px */
  letter-spacing: 1.2px;
  margin-top: 5vh !important;
}

.navlink {
  text-decoration: none !important;
}

.sub_text {
  color: #434343;
  font-family: AvenirLTProLight;
  font-size: 16px;
  font-style: normal;
  line-height: 150%;
  /* 36px */
  letter-spacing: 0.48px;
}

.gallery_btn {
  display: block;
  padding: 24px 38px 26px 38px;
  justify-content: flex-start;
  border-radius: 60px;
  background: #2350a2;
  color: #fff;
  font-family: AvenirLTProLight;
  font-size: 18px;
  margin-top: 20px;
  font-weight: 400;
  line-height: 130%;
  /* 31.2px */
  letter-spacing: 0.48px;
  text-decoration: none !important;
}

.gallery_btn:hover {
  background: #2350a2;
}

.grid_container {
  margin-top: 10vh !important;
  margin-bottom: 5vh !important;
}

@media screen and (min-width: "1085px") and (max-width: 1388px) {
  .about_image {
  }

  .content-overlay {
    width: 90%;
    /* Adjust width */
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1780px) {
  .about_image {
    width: 100%;
    height: 60%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1085px) {
  .background-video-container {
    height: 100vh;
    /* Adjust height */
  }

  .content-overlay {
    width: 90%;
    /* Adjust width */
    margin-left: auto;
    margin-right: auto;
  }

  .about {
    font-size: 36px;
    /* Adjust font size */
  }

  .projectName {
    font-size: 24px;
  }

  .para {
    font-size: 16px;
    /* Adjust font size */
    width: 100%;
    /* Adjust width */
  }

  .sub_text {
    font-size: 16px;
    /* Adjust font size */
  }

  .gallery_btn {
    font-size: 20px;
    /* Adjust font size */
    padding: 20px 20px;
    /* Adjust button padding */
  }

  .about_image {
    width: 100%;
    height: 50%;
  }
}

@media screen and (max-width: 768px) {
  .background-video-container {
    height: 70vh;
    /* Adjust height */
  }

  .content-overlay {
    width: 90%;
    /* Adjust width */
    margin-left: auto;
    margin-right: auto;
    margin-top: 8%;
    z-index: 0;
  }

  .header {
    z-index: 1000;
    /* Adjust the z-index as needed */
  }

  .about {
    font-size: 36px;
    /* Adjust font size */
    margin-top: 35vh;
  }

  .projectName {
    font-size: 23px;
    /* Adjust font size */
  }

  .para {
    font-size: 15px;
    /* Adjust font size */
    width: 100%;
    /* Adjust width */
  }

  .sub_text {
    font-size: 15px;
    /* Adjust font size */
  }

  .gallery_btn {
    font-size: 20px;
    /* Adjust font size */
    padding: 20px 28px;
    /* Adjust button padding */
  }

  .grid {
    margin-top: -14vh !important;
    margin: 2vh;
  }

  .about_image {
    margin-top: 2rem !important;
  }

  .footer {
    margin-top: 15vh;
  }
}

@media screen and (max-width: 460px) {
  .heading {
    font-size: calc(1.5rem + 1vw);
  }

  .gallery_btn {
    font-size: 20px;
    /* Adjust font size */
    padding: 16px 16px;
    /* Adjust button padding */
  }

  .grid {
    margin: 2vh;
  }

  .about_image {
    width: "100%";
    height: "20%" !important;
  }

  .footer {
    margin-top: 18vh;
  }
}

@media screen and (min-width: 300px) and (max-width: 400px) {
  .grid {
    margin: 2vh;
  }

  .footer {
    margin-top: 13vh;
  }
}

@media screen and (min-width: 200px) and (max-width: 300px) {
  .gallery_btn {
    font-size: calc(1rem + 1vw);
    /* Adjust font size */
    padding: 9px 7px;
    /* Adjust button padding */
  }

  .heading {
    font-size: calc(1rem + 1vw);
  }

  .grid_container {
    margin-top: -10vh !important;
  }
}

@media screen and (max-width: 287px) {
  .grid_container {
    margin-top: 20vh !important;
  }

  .about {
    font-size: 36px;
    /* Adjust font size */
  }

  .about {
    font-size: 26px;
    /* Adjust font size */
    margin-top: 35vh;
  }

  .projectName {
    font-size: 22px;
    /* Adjust font size */
  }

  .para {
    font-size: 16px;
    /* Adjust font size */
    width: 100%;
    /* Adjust width */
  }

  .sub_text {
    font-size: 16px;
    /* Adjust font size */
  }
}
