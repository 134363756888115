.UploadProgressContainer{

    
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 20%;
    right: 30%;
    left: 30%;
    height: 300px;
    /* width: 100%;
    
   display: flex;
   flex-direction: column;
   align-items: center; */
   padding: 2%;
   z-index: 1;
   border-radius: 20px;

}
.UploadSuccessImage{
    border: 0px solid;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 20px;
}
.UploadProgressBg{
    border: 0px solid;
    height: 25px;
    border-radius: 20px;
    overflow: hidden;
    background-color: rgb(233, 231, 231);
}
.UploadProgress{
    width: 20%;
    background-color: rgb(78, 241, 78);
    height: 100%;
    text-align: center;
    font-weight: 500;
    color: rgb(0, 0, 0);
}
.CloseButton{
    font-weight: 600;
    right: 3%;
    top: 5%;
    position: absolute;
    border: 0px solid;
    cursor: pointer;
}