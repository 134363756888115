/* Gallery Title */
@font-face {
  font-family: AvenirLTProLight;
  src: url(../../../public/AvenirLTProLight.otf);
}

/* Button Group */
.ButtonGroup {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  font-family: "Cormorant Garamond", serif;
}

.ButtonGroup .btn {
  margin-right: 10px;
  font-family: "Cormorant Garamond", serif;
}

/* Month Buttons */

.datePicker {
  padding: 10px;
  border: 0px;
  border-radius: 20px;

  margin: 0 auto;
  text-align: center;
  position: relative;
}

.scrollable-months::-webkit-scrollbar {
  display: none;
}
.select {
  font-size: 23px;
}
.months_font {
  color: #d4d4d4;
  font-family: AvenirLTProLight;
  font-size: 20px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 41.6px */
  letter-spacing: 0.64px;
  border: 0px;
  background-color: rgb(255, 255, 255);
  text-align: left;
}
.months_font:hover {
  background-color: rgb(255, 255, 255);
  color: var(--Brand-color, #2350a2) !important;
}

.MonthsButtonActive {
  /* border: 1px solid; */
  color: var(--Brand-color, #2350a2) !important;
  margin-left: 27.2%;
}
.scrollable-months {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px; /* Adjust height as needed */
  overflow-y: auto;
}

.scrollable-months .btn {
  width: calc(50% - 5px);
  margin-bottom: 5px;
  font-family: AvenirLTProLight;
}

/* Image Cards */
.Card {
  margin-bottom: 20px;
  font-family: "Cormorant Garamond", serif;
}

.GalleryCol {
  padding-right: 7%;
}

#bday-month {
  background: rgb(255, 255, 255);
  color: #000000; /* Change text color */
  border: 0px solid #000; /* Change border */
  /* Add any other styles you want to customize */
  width: 100%;
  padding: 6px;
  text-align: center;

  color: var(--Brand-color, #2350a2);
  font-family: "Cormorant Garamond", serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 36.4px */
  letter-spacing: 0.56px;

  border-top: 0.5px solid #d1d1d1;

  outline: none;
  background-color: #ffffff;
}


#bday-month::-webkit-calendar-picker-indicator {
  border: 0px solid;
  width: 100%;
  background-color: transparent;
  position: absolute;
  cursor: pointer;
}

.GalleryHeader {
  border-bottom: 1px solid #d6d6d6;
  margin-bottom: 36px;
  padding-bottom: 20px;
}

.GalleryHeaderText {
  color: #1a1a1a;

  font-family: "Cormorant Garamond", serif;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 41.6px */
  letter-spacing: 0.64px;
}
.GalleryHeaderText_month {
  font-size: 26px !important;
}
.imageCard {
  height: 300px;
  position: relative;
  border: none;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.imageCardImg {
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 16px;
  max-width: 280px;
  max-height: 280px;
}
.SelectDate-g {
  font-size: 20px;
  padding: 10px 10px;
  color: #000000;
  border-bottom: 1px solid #bebebe;
  text-align: left;
}

.LoadingBg {
  border: 0px solid;
  height: 600px;
  text-align: center;
}

.Loading {
  border: 0px solid;
  height: 100px;
  width: 100px;

  /* padding: 10%; */
}

.EditButton {
  border: 1px solid black;
  background-color: transparent;
  color: black;
  font-size: 15px;
  border-radius: 20px;
  font-weight: 500;
  padding: 10px 10px;
}

.EditButtonActive {
  border: 1px solid black;
  background-color: black;
  color: white;
  font-size: 15px;
  border-radius: 20px;
  font-weight: 500;
  padding: 10px 10px;
}

.EditButtonActive:hover {
  background-color: rgb(0, 0, 0);
  color: #ffffff;
}

.EditButton:hover {
  background-color: white;
  color: #000;
}

/* Responsive Styles */
@media screen and (min-width: 1028px) and (max-width: 1928px) {
  .datePicker {
    margin-top: 12vh;
    width: 80% !important;
  }
  .MonthsButtonActive {
    /* border: 1px solid; */
    color: var(--Brand-color, #2350a2);
    margin-left: 0%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1028px) {
  .months_font {
    font-size: 14px !important;
    width: 80% !important;
  }

  .datePicker {
    margin-top: 12vh;
  }
  .MonthsButtonActive {
    /* border: 1px solid; */
    color: var(--Brand-color, #2350a2);
    margin-left: 0%;
  }
}
@media (max-width: 768px) {
  .scrollable-months .btn {
    width: 100%;
  }
  .GalleryCol {
    padding-right: 9%;
  }
  .SelectDate-g {
    font-size: 22px !important;
  }
  .select {
  }
  .months_font {
    font-size: 20px !important;
    width: 95% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px !important;
  }
  .datePicker {
    width: 55% !important;
  }
}
@media screen and (min-width: 208px) and (max-width: 360px) {
  .months_font {
    font-size: 22px !important;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0px !important;
  }
  .datePicker {
    width: 100% !important;
  }
}
